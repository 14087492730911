{
  "objects": [
    {
      "type": "sun",
      "name": "Sun",
      "radius": {
        "actual": 695.7,
        "simple": 6
      },
      "rotationPeriod": 25.05,
      "color": "0xFFD300",
      "atmosphere": {
        "color": "0xFFD300"
      },
      "satellites": [
        {
          "type": "planet",
          "name": "Mercury",
          "radius": {
            "actual": 2.439,
            "simple": 0.5
          },
          "color": "0x7d827e",
          "rotationPeriod": 58.646,
          "axialTilt": 0.034,
          "orbit": {
            "e": 0.20563,
            "aphelion": {
              "actual": 69816.9,
              "simple": 20
            },
            "perihelion": {
              "actual": 46001.2,
              "simple": 18
            },
            "period": 87.9691,
            "inclination": 7.005,
            "ascNodeLong": 48.331,
            "perihelionArg": 29.124
          }
        },
        {
          "type": "planet",
          "name": "Venus",
          "radius": {
            "actual": 6.0518,
            "simple": 1.3
          },
          "color": "0xc18f17",
          "rotationPeriod": -243.0226,
          "axialTilt": 177.36,
          "orbit": {
            "e": 0.006772,
            "aphelion": {
              "actual": 108939.0,
              "simple": 26
            },
            "perihelion": {
              "actual": 107477.0,
              "simple": 26
            },
            "period": 224.701,
            "inclination": 3.39458,
            "ascNodeLong": 76.68,
            "perihelionArg": 54.884
          }
        },
        {
          "type": "planet",
          "name": "Earth",
          "radius": {
            "actual": 6.371,
            "simple": 1.5
          },
          "color": "0x6b93d6",
          "rotationPeriod": 0.997269,
          "axialTilt": 23.4392811,
          "orbit": {
            "e": 0.0167086,
            "aphelion": {
              "actual": 152100.0,
              "simple": 32
            },
            "perihelion": {
              "actual": 147095.0,
              "simple": 31
            },
            "period": 365.256363,
            "inclination": 0,
            "ascNodeLong": -11.26064,
            "perihelionArg": 114.20783
          },
          "satellites": [
            {
              "type": "moon",
              "name": "Moon",
              "radius": {
                "actual": 1.7374,
                "simple": 0.3
              },
              "color": "0xc9c9c9",
              "rotationPeriod": 27.321661,
              "orbit": {
                "e": 0.0549,
                "aphelion": {
                  "actual": 405.4,
                  "simple": 2.8
                },
                "perihelion": {
                  "actual": 362.6,
                  "simple": 2.7
                },
                "period": 27.321661,
                "inclination": 5.145
              }
            }
          ]
        },
        {
          "type": "planet",
          "name": "Mars",
          "radius": {
            "actual": 3.3895,
            "simple": 0.8
          },
          "color": "0xc1440e",
          "rotationPeriod": 1.025957,
          "axialTilt": 25.19,
          "orbit": {
            "e": 0.0934,
            "aphelion": {
              "actual": 249200.0,
              "simple": 40
            },
            "perihelion": {
              "actual": 206700.0,
              "simple": 38
            },
            "period": 686.98,
            "inclination": 1.85,
            "ascNodeLong": 49.57854,
            "perihelionArg": 286.5
          },
          "satellites": [
            {
              "type": "moon",
              "name": "Phobos",
              "radius": {
                "actual": 0.0112667,
                "simple": 0.1
              },
              "color": "0xc9c9c9",
              "orbit": {
                "e": 0.0151,
                "aphelion": {
                  "actual": 9.51758,
                  "simple": 1.7
                },
                "perihelion": {
                  "actual": 9.23442,
                  "simple": 1.6
                },
                "period": 0.31891023,
                "inclination": 26.04
              }
            },
            {
              "type": "moon",
              "name": "Deimos",
              "radius": {
                "actual": 0.0062,
                "simple": 0.09
              },
              "color": "0xc9c9c9",
              "orbit": {
                "e": 0.00033,
                "aphelion": {
                  "actual": 23.4709,
                  "simple": 2.1
                },
                "perihelion": {
                  "actual": 23.4555,
                  "simple": 2.0
                },
                "period": 1.263,
                "inclination": 27.58
              }
            }
          ]
        },
        {
          "type": "gas giant",
          "name": "Jupiter",
          "radius": {
            "actual": 69.911,
            "simple": 3
          },
          "color": "0xc99039",
          "rotationPeriod": 0.41354167,
          "axialTilt": 3.13,
          "orbit": {
            "e": 0.0489,
            "aphelion": {
              "actual": 816363.0,
              "simple": 80
            },
            "perihelion": {
              "actual": 740595.0,
              "simple": 75
            },
            "period": 4332.59,
            "inclination": 1.303,
            "ascNodeLong": 100.464,
            "perihelionArg": 273.867
          },
          "satellites": [
            {
              "type": "moon",
              "name": "Metis",
              "radius": {
                "actual": 0.0215,
                "simple": 0.1
              },
              "color": "0xc9c9c9",
              "orbit": {
                "e": 0.0002,
                "aphelion": {
                  "actual": 128.026,
                  "simple": 3.7
                },
                "perihelion": {
                  "actual": 127.974,
                  "simple": 3.7
                },
                "period": 0.29478,
                "inclination": 1.363
              }
            },
            {
              "type": "moon",
              "name": "Adrastea",
              "radius": {
                "actual": 0.0082,
                "simple": 0.09
              },
              "color": "0xc9c9c9",
              "orbit": {
                "e": 0.0015,
                "aphelion": {
                  "actual": 129.0,
                  "simple": 3.8
                },
                "perihelion": {
                  "actual": 129.0,
                  "simple": 3.8
                },
                "period": 0.29826,
                "inclination": 1.333
              }
            },
            {
              "type": "moon",
              "name": "Amalthea",
              "radius": {
                "actual": 0.0835,
                "simple": 0.12
              },
              "color": "0xc9c9c9",
              "orbit": {
                "e": 0.00319,
                "aphelion": {
                  "actual": 182.84,
                  "simple": 4.1
                },
                "perihelion": {
                  "actual": 181.15,
                  "simple": 4.1
                },
                "period": 0.49817943,
                "inclination": 1.677
              }
            },
            {
              "type": "moon",
              "name": "Thebe",
              "radius": {
                "actual": 0.0493,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.0175,
                "aphelion": {
                  "actual": 226.0,
                  "simple": 4.3
                },
                "perihelion": {
                  "actual": 218.0,
                  "simple": 4.3
                },
                "period": 0.674536,
                "inclination": 2.379
              }
            },
            {
              "type": "moon",
              "name": "Io",
              "radius": {
                "actual": 1.8216,
                "simple": 0.3
              },
              "color": "0xffd966",
              "orbit": {
                "e": 0.0041,
                "aphelion": {
                  "actual": 423.4,
                  "simple": 5
                },
                "perihelion": {
                  "actual": 420.0,
                  "simple": 5
                },
                "period": 1.769137786,
                "inclination": 2.213
              }
            },
            {
              "type": "moon",
              "name": "Europa",
              "radius": {
                "actual": 1.5608,
                "simple": 0.3
              },
              "color": "0xce7e00",
              "orbit": {
                "e": 0.009,
                "aphelion": {
                  "actual": 676.938,
                  "simple": 6
                },
                "perihelion": {
                  "actual": 664.862,
                  "simple": 6
                },
                "period": 3.551181,
                "inclination": 1.791
              }
            },
            {
              "type": "moon",
              "name": "Ganymede",
              "radius": {
                "actual": 2.6341,
                "simple": 0.5
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.009,
                "aphelion": {
                  "actual": 1071.6,
                  "simple": 6.8
                },
                "perihelion": {
                  "actual": 1069.2,
                  "simple": 6.8
                },
                "period": 7.15455296,
                "inclination": 2.214
              }
            },
            {
              "type": "moon",
              "name": "Callisto",
              "radius": {
                "actual": 2.4103,
                "simple": 0.5
              },
              "color": "0xa0bdb7",

              "orbit": {
                "e": 0.0074,
                "aphelion": {
                  "actual": 1897.0,
                  "simple": 7.8
                },
                "perihelion": {
                  "actual": 1869.0,
                  "simple": 7.8
                },
                "period": 16.6890184,
                "inclination": 2.017
              }
            },
            {
              "type": "moon",
              "name": "Themisto",
              "radius": {
                "actual": 0.0045,
                "simple": 0.09
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2522112,
                "aphelion": {
                  "actual": 7396.13,
                  "simple": 10
                },
                "perihelion": {
                  "actual": 7396.13,
                  "simple": 10
                },
                "period": 129.95,
                "inclination": 45.28121,
                "ascNodeLong": 192.64162,
                "perihelionArg": 241.25168
              }
            },
            {
              "type": "moon",
              "name": "Leda",
              "radius": {
                "actual": 0.0105,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.1648788,
                "aphelion": {
                  "actual": 11195.98,
                  "simple": 12
                },
                "perihelion": {
                  "actual": 11195.98,
                  "simple": 12
                },
                "period": 242.02,
                "inclination": 27.63631,
                "ascNodeLong": 190.18497,
                "perihelionArg": 312.92965
              }
            },
            {
              "type": "moon",
              "name": "Ersa",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.094,
                "aphelion": {
                  "actual": 11483.0,
                  "simple": 12.5
                },
                "perihelion": {
                  "actual": 11483.0,
                  "simple": 12.5
                },
                "period": 252.0,
                "inclination": 30.61,
                "ascNodeLong": 93.3,
                "perihelionArg": 346.7
              }
            },
            {
              "type": "moon",
              "name": "Himalia",
              "radius": {
                "actual": 0.1396,
                "simple": 0.2
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.094,
                "aphelion": {
                  "actual": 11388.69,
                  "simple": 12.45
                },
                "perihelion": {
                  "actual": 11388.69,
                  "simple": 12.45
                },
                "period": 248.29,
                "inclination": 29.90917,
                "ascNodeLong": 44.99935,
                "perihelionArg": 21.60643
              }
            },
            {
              "type": "moon",
              "name": "Pandia",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.18,
                "aphelion": {
                  "actual": 11525.0,
                  "simple": 12.52
                },
                "perihelion": {
                  "actual": 11525.0,
                  "simple": 12.52
                },
                "period": 252.1,
                "inclination": 28.15,
                "ascNodeLong": 228.4,
                "perihelionArg": 238.8
              }
            },
            {
              "type": "moon",
              "name": "Lysithea",
              "radius": {
                "actual": 0.0211,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.1478734,
                "aphelion": {
                  "actual": 11700.71,
                  "simple": 12.6
                },
                "perihelion": {
                  "actual": 11700.71,
                  "simple": 12.6
                },
                "period": 258.57,
                "inclination": 26.29254,
                "ascNodeLong": 343.46495,
                "perihelionArg": 94.8001
              }
            },
            {
              "type": "moon",
              "name": "Elara",
              "radius": {
                "actual": 0.04,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.1961487,
                "aphelion": {
                  "actual": 11703.13,
                  "simple": 12.6
                },
                "perihelion": {
                  "actual": 11703.13,
                  "simple": 12.6
                },
                "period": 258.65,
                "inclination": 30.51712,
                "ascNodeLong": 90.86474,
                "perihelionArg": 191.19922
              }
            },
            {
              "type": "moon",
              "name": "Dia",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.211,
                "aphelion": {
                  "actual": 12118.0,
                  "simple": 12.8
                },
                "perihelion": {
                  "actual": 12118.0,
                  "simple": 12.8
                },
                "period": 287.0,
                "inclination": 28.23,
                "ascNodeLong": 290.9,
                "perihelionArg": 178.0
              }
            },
            {
              "type": "moon",
              "name": "Carpo",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.43,
                "aphelion": {
                  "actual": 16989.0,
                  "simple": 13.2
                },
                "perihelion": {
                  "actual": 16989.0,
                  "simple": 13.2
                },
                "period": 456.1,
                "inclination": 51.4,
                "ascNodeLong": 60.9,
                "perihelionArg": 90.0
              }
            },
            {
              "type": "moon",
              "name": "Valetudo",
              "radius": {
                "actual": 0.0005,
                "simple": 0.06
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2018315,
                "aphelion": {
                  "actual": 18819.02,
                  "simple": 13.6
                },
                "perihelion": {
                  "actual": 18819.02,
                  "simple": 13.6
                },
                "period": 527.41,
                "inclination": 32.03294,
                "ascNodeLong": 235.45916,
                "perihelionArg": 122.37546
              }
            },
            {
              "type": "moon",
              "name": "Euporie",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.144,
                "aphelion": {
                  "actual": 19302.0,
                  "simple": 13.8
                },
                "perihelion": {
                  "actual": 19302.0,
                  "simple": 13.8
                },
                "period": -550.7,
                "inclination": 145.8,
                "ascNodeLong": 64.9,
                "perihelionArg": 74.6
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LV",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.105,
                "aphelion": {
                  "actual": 20274.0,
                  "simple": 13.9
                },
                "perihelion": {
                  "actual": 20274.0,
                  "simple": 13.9
                },
                "period": -588.0,
                "inclination": 146.4,
                "ascNodeLong": 215.5,
                "perihelionArg": 98.15
              }
            },
            {
              "type": "moon",
              "name": "Eupheme",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.253,
                "aphelion": {
                  "actual": 21199.71,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21199.71,
                  "simple": 14.1
                },
                "period": -628.06,
                "inclination": 148.0,
                "ascNodeLong": 292.0,
                "perihelionArg": 109.0
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LII",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.307,
                "aphelion": {
                  "actual": 20307.15,
                  "simple": 13.9
                },
                "perihelion": {
                  "actual": 20307.15,
                  "simple": 13.9
                },
                "period": -588.1,
                "inclination": 150.4
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LIV",
              "radius": {
                "actual": 0.0005,
                "simple": 0.06
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.141,
                "aphelion": {
                  "actual": 20650.845,
                  "simple": 13.9
                },
                "perihelion": {
                  "actual": 20650.845,
                  "simple": 13.9
                },
                "period": -602.7,
                "inclination": 139.8,
                "ascNodeLong": 293.8,
                "perihelionArg": 328.2
              }
            },
            {
              "type": "moon",
              "name": "Mneme",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.227,
                "aphelion": {
                  "actual": 21069.0,
                  "simple": 14
                },
                "perihelion": {
                  "actual": 21069.0,
                  "simple": 14
                },
                "period": -602.7,
                "inclination": 148.6,
                "ascNodeLong": 18.1,
                "perihelionArg": 41.7
              }
            },
            {
              "type": "moon",
              "name": "Euanthe",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.232,
                "aphelion": {
                  "actual": 20799.0,
                  "simple": 13.9
                },
                "perihelion": {
                  "actual": 20799.0,
                  "simple": 13.9
                },
                "period": -602.81,
                "inclination": 148.9,
                "ascNodeLong": 271.0,
                "perihelionArg": 316.0
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 16",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.3330999,
                "aphelion": {
                  "actual": 20512.0,
                  "simple": 13.8
                },
                "perihelion": {
                  "actual": 20512.0,
                  "simple": 13.8
                },
                "period": -600.18,
                "inclination": 151.16323,
                "ascNodeLong": 83.26365,
                "perihelionArg": 86.51495
              }
            },
            {
              "type": "moon",
              "name": "Harpalyke",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.1602677,
                "aphelion": {
                  "actual": 21280.18,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21280.18,
                  "simple": 14.1
                },
                "period": -634.19,
                "inclination": 148.29788,
                "ascNodeLong": 92.54746,
                "perihelionArg": 193.28018
              }
            },
            {
              "type": "moon",
              "name": "Orthosie",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.4837243,
                "aphelion": {
                  "actual": 21170.54,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21170.54,
                  "simple": 14.1
                },
                "period": -629.29,
                "inclination": 148.4874,
                "ascNodeLong": 287.90005,
                "perihelionArg": 261.21085
              }
            },
            {
              "type": "moon",
              "name": "Helike",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.156,
                "aphelion": {
                  "actual": 21263.0,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21263.0,
                  "simple": 14.1
                },
                "period": -634.8,
                "inclination": 154.8,
                "ascNodeLong": 100.3,
                "perihelionArg": 314.7
              }
            },
            {
              "type": "moon",
              "name": "Praxidike",
              "radius": {
                "actual": 0.0035,
                "simple": 0.085
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.23,
                "aphelion": {
                  "actual": 21147.0,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21147.0,
                  "simple": 14.1
                },
                "period": -609.25,
                "inclination": 149.0,
                "ascNodeLong": 285.2,
                "perihelionArg": 209.7
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXIV",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.148,
                "aphelion": {
                  "actual": 20694.0,
                  "simple": 13.9
                },
                "perihelion": {
                  "actual": 20694.0,
                  "simple": 13.9
                },
                "period": -606.3,
                "inclination": 147.9,
                "ascNodeLong": 82.5,
                "perihelionArg": 171.6
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 12",
              "radius": {
                "actual": 0.0005,
                "simple": 0.06
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.3657005,
                "aphelion": {
                  "actual": 21557.74,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21557.74,
                  "simple": 14.1
                },
                "period": -646.64,
                "inclination": 154.69036,
                "ascNodeLong": 127.52296,
                "perihelionArg": 86.84711
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXVIII",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.3657005,
                "aphelion": {
                  "actual": 20627.0,
                  "simple": 13.8
                },
                "perihelion": {
                  "actual": 20627.0,
                  "simple": 13.8
                },
                "period": -602.6,
                "inclination": 143.4,
                "ascNodeLong": 321.5,
                "perihelionArg": 323.5
              }
            },
            {
              "type": "moon",
              "name": "Thelxinoe",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.221,
                "aphelion": {
                  "actual": 21162.0,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21162.0,
                  "simple": 14.1
                },
                "period": -628.1,
                "inclination": 151.4,
                "ascNodeLong": 206.2,
                "perihelionArg": 179.8
              }
            },
            {
              "type": "moon",
              "name": "Thyone",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.229,
                "aphelion": {
                  "actual": 20940.0,
                  "simple": 13.9
                },
                "perihelion": {
                  "actual": 20940.0,
                  "simple": 13.9
                },
                "period": -603.58,
                "inclination": 148.5,
                "ascNodeLong": 243.0,
                "perihelionArg": 89.1
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 2",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2776569,
                "aphelion": {
                  "actual": 20554.39,
                  "simple": 13.9
                },
                "perihelion": {
                  "actual": 20554.39,
                  "simple": 13.9
                },
                "period": -602.02,
                "inclination": 149.20392,
                "ascNodeLong": 50.46976,
                "perihelionArg": 224.95527
              }
            },
            {
              "type": "moon",
              "name": "Ananke",
              "radius": {
                "actual": 0.015,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.1747248,
                "aphelion": {
                  "actual": 21042.47,
                  "simple": 14.1
                },
                "perihelion": {
                  "actual": 21042.47,
                  "simple": 14.1
                },
                "period": -623.59,
                "inclination": 148.67482,
                "ascNodeLong": 86.44368,
                "perihelionArg": 135.63033
              }
            },
            {
              "type": "moon",
              "name": "Iocaste",
              "radius": {
                "actual": 0.0025,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.3294908,
                "aphelion": {
                  "actual": 21431.65,
                  "simple": 14.2
                },
                "perihelion": {
                  "actual": 21431.65,
                  "simple": 14.2
                },
                "period": -640.97,
                "inclination": 149.42446,
                "ascNodeLong": 343.53045,
                "perihelionArg": 110.27239
              }
            },
            {
              "type": "moon",
              "name": "Hermippe",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.198252,
                "aphelion": {
                  "actual": 20665.87,
                  "simple": 13.8
                },
                "perihelion": {
                  "actual": 20665.87,
                  "simple": 13.8
                },
                "period": -606.93,
                "inclination": 146.76001,
                "ascNodeLong": 37.24213,
                "perihelionArg": 356.27211
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXX",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.229,
                "aphelion": {
                  "actual": 21487.0,
                  "simple": 14.2
                },
                "perihelion": {
                  "actual": 21487.0,
                  "simple": 14.2
                },
                "period": -639.2,
                "inclination": 152.7,
                "ascNodeLong": 306.4,
                "perihelionArg": 317.7
              }
            },
            {
              "type": "moon",
              "name": "Philophrosyne",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.194,
                "aphelion": {
                  "actual": 22819.95,
                  "simple": 14.5
                },
                "perihelion": {
                  "actual": 22819.95,
                  "simple": 14.5
                },
                "period": -701.3,
                "inclination": 143.6
              }
            },
            {
              "type": "moon",
              "name": "Pasithee",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2097026,
                "aphelion": {
                  "actual": 22967.99,
                  "simple": 14.55
                },
                "perihelion": {
                  "actual": 22967.99,
                  "simple": 14.55
                },
                "period": -711.12,
                "inclination": 164.72796,
                "ascNodeLong": 49.07994,
                "perihelionArg": 331.51895
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXIX",
              "radius": {
                "actual": 0.0005,
                "simple": 0.06
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.312,
                "aphelion": {
                  "actual": 23232.7,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23232.7,
                  "simple": 14.6
                },
                "period": -719.6,
                "inclination": 164.7,
                "ascNodeLong": 160.3,
                "perihelionArg": 45.1
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 24",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.25,
                "aphelion": {
                  "actual": 23088.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23088.0,
                  "simple": 14.6
                },
                "period": -715.4,
                "inclination": 162,
                "ascNodeLong": 160.3,
                "perihelionArg": 45.1
              }
            },
            {
              "type": "moon",
              "name": "Eurydome",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2975371,
                "aphelion": {
                  "actual": 23214.49,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23214.49,
                  "simple": 14.6
                },
                "period": -722.59,
                "inclination": 150.28897,
                "ascNodeLong": 31.50527,
                "perihelionArg": 306.06428
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LVI",
              "radius": {
                "actual": 0.0005,
                "simple": 0.06
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.332,
                "aphelion": {
                  "actual": 23463.885,
                  "simple": 14.65
                },
                "perihelion": {
                  "actual": 23463.885,
                  "simple": 14.65
                },
                "period": -730.5,
                "inclination": 148.8
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 4",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.4967418,
                "aphelion": {
                  "actual": 22048.57,
                  "simple": 14.4
                },
                "perihelion": {
                  "actual": 22048.57,
                  "simple": 14.4
                },
                "period": -668.85,
                "inclination": 149.40138,
                "ascNodeLong": 249.917,
                "perihelionArg": 240.54004
              }
            },
            {
              "type": "moon",
              "name": "Chaldene",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.1500864,
                "aphelion": {
                  "actual": 24006.28,
                  "simple": 14.7
                },
                "perihelion": {
                  "actual": 24006.28,
                  "simple": 14.7
                },
                "period": -759.88,
                "inclination": 164.25379,
                "ascNodeLong": 215.26817,
                "perihelionArg": 340.66981
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXIII",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.236,
                "aphelion": {
                  "actual": 23303.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23303.0,
                  "simple": 14.6
                },
                "period": -723.1,
                "inclination": 166.4,
                "ascNodeLong": 74.7,
                "perihelionArg": 231.9
              }
            },
            {
              "type": "moon",
              "name": "Isonoe",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2263119,
                "aphelion": {
                  "actual": 23322.67,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23322.67,
                  "simple": 14.6
                },
                "period": -688.61,
                "inclination": 164.45891,
                "ascNodeLong": 203.99552,
                "perihelionArg": 219.75296
              }
            },
            {
              "type": "moon",
              "name": "Kallichore",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.264,
                "aphelion": {
                  "actual": 23112.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23112.0,
                  "simple": 14.6
                },
                "period": -717.806,
                "inclination": 165.5,
                "ascNodeLong": 41.5,
                "perihelionArg": 18.5
              }
            },
            {
              "type": "moon",
              "name": "Erinome",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2052559,
                "aphelion": {
                  "actual": 22354.2,
                  "simple": 14.5
                },
                "perihelion": {
                  "actual": 22354.2,
                  "simple": 14.5
                },
                "period": -682.8,
                "inclination": 164.81976,
                "ascNodeLong": 34.0266,
                "perihelionArg": 81.1176
              }
            },
            {
              "type": "moon",
              "name": "Kale",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2893464,
                "aphelion": {
                  "actual": 23512.34,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23512.34,
                  "simple": 14.6
                },
                "period": -736.55,
                "inclination": 166.17658,
                "ascNodeLong": 153.58621,
                "perihelionArg": 138.9124
              }
            },
            {
              "type": "moon",
              "name": "Eirene",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2413,
                "aphelion": {
                  "actual": 23731.77,
                  "simple": 14.65
                },
                "perihelion": {
                  "actual": 23731.77,
                  "simple": 14.65
                },
                "period": -743.88,
                "inclination": 162.713
              }
            },
            {
              "type": "moon",
              "name": "Aitne",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.264,
                "aphelion": {
                  "actual": 23231.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23231.0,
                  "simple": 14.6
                },
                "period": -712.04,
                "inclination": 165.1,
                "ascNodeLong": 24.5,
                "perihelionArg": 122.2
              }
            },
            {
              "type": "moon",
              "name": "Eukelade",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.272,
                "aphelion": {
                  "actual": 23661.0,
                  "simple": 14.65
                },
                "perihelion": {
                  "actual": 23661.0,
                  "simple": 14.65
                },
                "period": -693.02,
                "inclination": 165.5,
                "ascNodeLong": 206.3,
                "perihelionArg": 325.6
              }
            },
            {
              "type": "moon",
              "name": "Arche",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.259,
                "aphelion": {
                  "actual": 22931.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 22931.0,
                  "simple": 14.6
                },
                "period": -723.9,
                "inclination": 165.0,
                "ascNodeLong": 350.7,
                "perihelionArg": 161.1
              }
            },
            {
              "type": "moon",
              "name": "Taygete",
              "radius": {
                "actual": 0.0025,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2487538,
                "aphelion": {
                  "actual": 22546.24,
                  "simple": 14.5
                },
                "perihelion": {
                  "actual": 22546.24,
                  "simple": 14.5
                },
                "period": -691.62,
                "inclination": 165.95236,
                "ascNodeLong": 14.91608,
                "perihelionArg": 283.34358
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXXII",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.233,
                "aphelion": {
                  "actual": 22462.0,
                  "simple": 14.5
                },
                "perihelion": {
                  "actual": 22462.0,
                  "simple": 14.5
                },
                "period": -686.6,
                "inclination": 163.3,
                "ascNodeLong": 323.9,
                "perihelionArg": 127.2
              }
            },
            {
              "type": "moon",
              "name": "Carme",
              "radius": {
                "actual": 0.02335,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2294925,
                "aphelion": {
                  "actual": 22579.85,
                  "simple": 14.5
                },
                "perihelion": {
                  "actual": 22579.85,
                  "simple": 14.5
                },
                "period": -693.17,
                "inclination": 163.53496,
                "ascNodeLong": 209.94088,
                "perihelionArg": 133.45035
              }
            },
            {
              "type": "moon",
              "name": "Herse",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2,
                "aphelion": {
                  "actual": 23097.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23097.0,
                  "simple": 14.6
                },
                "period": -715.4,
                "inclination": 164.2,
                "ascNodeLong": 329.0,
                "perihelionArg": 355.7
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXI",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.257,
                "aphelion": {
                  "actual": 22757.0,
                  "simple": 14.58
                },
                "perihelion": {
                  "actual": 22757.0,
                  "simple": 14.58
                },
                "period": -697.6,
                "inclination": 166.7,
                "ascNodeLong": 105.7,
                "perihelionArg": 284.1
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LI",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.32,
                "aphelion": {
                  "actual": 23314.335,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23314.335,
                  "simple": 14.6
                },
                "period": -723.2,
                "inclination": 163.2
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 9",
              "radius": {
                "actual": 0.0005,
                "simple": 0.06
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.17019,
                "aphelion": {
                  "actual": 24168.66,
                  "simple": 14.7
                },
                "perihelion": {
                  "actual": 24168.66,
                  "simple": 14.7
                },
                "period": -767.6,
                "inclination": 166.33403,
                "ascNodeLong": 130.59522,
                "perihelionArg": 45.62861
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXVI",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.284,
                "aphelion": {
                  "actual": 23232.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23232.0,
                  "simple": 14.6
                },
                "period": -719.5,
                "inclination": 164.3,
                "ascNodeLong": 118.2,
                "perihelionArg": 11.9
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LXVII",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.557,
                "aphelion": {
                  "actual": 22455.0,
                  "simple": 14.5
                },
                "perihelion": {
                  "actual": 22455.0,
                  "simple": 14.5
                },
                "period": -683,
                "inclination": 155.2
              }
            },
            {
              "type": "moon",
              "name": "Kalyke",
              "radius": {
                "actual": 0.0045,
                "simple": 0.085
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.3028225,
                "aphelion": {
                  "actual": 24147.77,
                  "simple": 14.7
                },
                "perihelion": {
                  "actual": 24147.77,
                  "simple": 14.7
                },
                "period": -766.61,
                "inclination": 165.9373,
                "ascNodeLong": 132.43876,
                "perihelionArg": 323.78885
              }
            },
            {
              "type": "moon",
              "name": "Hegemone",
              "radius": {
                "actual": 0.0015,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.328,
                "aphelion": {
                  "actual": 23947.0,
                  "simple": 14.7
                },
                "perihelion": {
                  "actual": 23947.0,
                  "simple": 14.7
                },
                "period": -739.6,
                "inclination": 155.2
              }
            },
            {
              "type": "moon",
              "name": "Pasiphae",
              "radius": {
                "actual": 0.029,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.6110162,
                "aphelion": {
                  "actual": 23208.94,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23208.94,
                  "simple": 14.6
                },
                "period": -722.34,
                "inclination": 153.40903,
                "ascNodeLong": 19.11682,
                "perihelionArg": 241.59647
              }
            },
            {
              "type": "moon",
              "name": "Sponde",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.312,
                "aphelion": {
                  "actual": 23487.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23487.0,
                  "simple": 14.6
                },
                "period": -734.89,
                "inclination": 151.0
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 10",
              "lost": true,
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.2065969,
                "aphelion": {
                  "actual": 22896.0,
                  "simple": 14.55
                },
                "perihelion": {
                  "actual": 22896.0,
                  "simple": 14.55
                },
                "period": -707.78,
                "inclination": 163.48126,
                "ascNodeLong": 241.8219,
                "perihelionArg": 270.05055
              }
            },
            {
              "type": "moon",
              "name": "Megaclite",
              "radius": {
                "actual": 0.003,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.410217,
                "aphelion": {
                  "actual": 23736.19,
                  "simple": 14.65
                },
                "perihelion": {
                  "actual": 23736.19,
                  "simple": 14.65
                },
                "period": -747.09,
                "inclination": 143.20224,
                "ascNodeLong": 356.52408,
                "perihelionArg": 8.71854
              }
            },
            {
              "type": "moon",
              "name": "Cyllene",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.319,
                "aphelion": {
                  "actual": 24349.0,
                  "simple": 14.7
                },
                "perihelion": {
                  "actual": 24349.0,
                  "simple": 14.7
                },
                "period": -737.8,
                "inclination": 149.3
              }
            },
            {
              "type": "moon",
              "name": "Sinope",
              "radius": {
                "actual": 0.018,
                "simple": 0.1
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.336655,
                "aphelion": {
                  "actual": 24371.65,
                  "simple": 14.7
                },
                "perihelion": {
                  "actual": 24371.65,
                  "simple": 14.7
                },
                "period": -777.29,
                "inclination": 158.6384,
                "ascNodeLong": 8.61437,
                "perihelionArg": 60.30205
              }
            },
            {
              "type": "moon",
              "name": "Jupiter LIX",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.397,
                "aphelion": {
                  "actual": 23547.105,
                  "simple": 14.65
                },
                "perihelion": {
                  "actual": 23547.105,
                  "simple": 14.65
                },
                "period": -734.2,
                "inclination": 149.2
              }
            },
            {
              "type": "moon",
              "name": "Aoede",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.432,
                "aphelion": {
                  "actual": 23981.0,
                  "simple": 14.65
                },
                "perihelion": {
                  "actual": 23981.0,
                  "simple": 14.65
                },
                "period": -761.5,
                "inclination": 158.3
              }
            },
            {
              "type": "moon",
              "name": "Autonoe",
              "radius": {
                "actual": 0.002,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.334,
                "aphelion": {
                  "actual": 23039.0,
                  "simple": 14.6
                },
                "perihelion": {
                  "actual": 23039.0,
                  "simple": 14.6
                },
                "period": -719.01,
                "inclination": 152.9
              }
            },
            {
              "type": "moon",
              "name": "Callirrhoe",
              "radius": {
                "actual": 0.0048,
                "simple": 0.085
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.3095704,
                "aphelion": {
                  "actual": 24583.09,
                  "simple": 14.75
                },
                "perihelion": {
                  "actual": 24583.09,
                  "simple": 14.75
                },
                "period": -787.43,
                "inclination": 147.9979,
                "ascNodeLong": 352.7548,
                "perihelionArg": 68.21981
              }
            },
            {
              "type": "moon",
              "name": "S/2003 J 23",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.3207567,
                "aphelion": {
                  "actual": 24678.19,
                  "simple": 14.75
                },
                "perihelion": {
                  "actual": 24678.19,
                  "simple": 14.75
                },
                "period": -792,
                "inclination": 146.15464,
                "ascNodeLong": 142.40639,
                "perihelionArg": 295.7309
              }
            },
            {
              "type": "moon",
              "name": "Kore",
              "radius": {
                "actual": 0.001,
                "simple": 0.08
              },
              "color": "0xc9c9c9",

              "orbit": {
                "e": 0.325,
                "aphelion": {
                  "actual": 24543.0,
                  "simple": 14.75
                },
                "perihelion": {
                  "actual": 24543.0,
                  "simple": 14.75
                },
                "period": -779.2,
                "inclination": 145.0
              }
            }
          ],
          "ringSystem": [
            {
              "type": "ring",
              "name": "Halo Ring",
              "radius": {
                "actual": 92.0,
                "simple": 3.956
              },
              "width": {
                "actual": 30.5,
                "simple": 1.312
              },
              "thickness": {
                "actual": 12.5,
                "simple": 0.5375
              },
              "opticalDepth": 0.01,
              "color": "0x2d8fd6"
            },
            {
              "type": "ring",
              "name": "Main Ring",
              "radius": {
                "actual": 122.5,
                "simple": 5.2675
              },
              "width": {
                "actual": 6.5,
                "simple": 0.2795
              },
              "thickness": {
                "actual": 0.3,
                "simple": 0.0129
              },
              "opticalDepth": 0.01,
              "color": "0xc1440e"
            },
            {
              "type": "ring",
              "name": "Amalthea Gossamer Ring",
              "radius": {
                "actual": 129.0,
                "simple": 5.547
              },
              "width": {
                "actual": 53.0,
                "simple": 2.279
              },
              "thickness": {
                "actual": 2.0,
                "simple": 0.086
              },
              "opticalDepth": 0.01,
              "color": "0xc1440e"
            },
            {
              "type": "ring",
              "name": "Thebe Gossamer Ring",
              "radius": {
                "actual": 129.0,
                "simple": 5.547
              },
              "width": {
                "actual": 97.0,
                "simple": 4.171
              },
              "thickness": {
                "actual": 8.4,
                "simple": 0.4032
              },
              "opticalDepth": 0.01,
              "color": "0xc1440e"
            }
          ]
        },
        {
          "type": "gas giant",
          "name": "Saturn",
          "radius": {
            "actual": 58.232,
            "simple": 2.8
          },
          "color": "0xbfbdaf",
          "rotationPeriod": 0.4375,
          "axialTilt": 26.73,
          "orbit": {
            "e": 0.0565,
            "aphelion": {
              "actual": 1514500.0,
              "simple": 120
            },
            "perihelion": {
              "actual": 1352550.0,
              "simple": 115
            },
            "period": 10759.22,
            "inclination": 2.485,
            "ascNodeLong": 113.665,
            "perihelionArg": 339.392
          },
          "satellites": [],
          "ringSystem": [
            {
              "type": "ring",
              "name": "D Ring",
              "radius": {
                "actual": 66.9,
                "simple": 3.2
              },
              "width": {
                "actual": 7.5,
                "simple": 0.36
              },
              "opticalDepth": 0.05,
              "color": "0x655f45"
            },
            {
              "type": "ring",
              "name": "C Ring",
              "radius": {
                "actual": 74.658,
                "simple": 3.584
              },
              "width": {
                "actual": 17.5,
                "simple": 0.84
              },
              "opticalDepth": 0.05,
              "color": "0xd8ae6d",
              "parts": [
                {
                  "type": "gap",
                  "name": "Colombo Gap",
                  "radius": {
                    "actual": 77.87,
                    "simple": 3.74
                  },
                  "width": {
                    "actual": 0.15,
                    "simple": 0.0072
                  }
                },
                {
                  "type": "ringlet",
                  "name": "Titan Ringlet",
                  "radius": {
                    "actual": 77.87,
                    "simple": 3.74
                  },
                  "width": {
                    "actual": 0.025,
                    "simple": 0.0012
                  }
                },
                {
                  "type": "gap",
                  "name": "Maxwell Gap",
                  "radius": {
                    "actual": 87.491,
                    "simple": 4.2
                  },
                  "width": {
                    "actual": 0.27,
                    "simple": 0.013
                  }
                },
                {
                  "type": "ringlet",
                  "name": "Maxwell Ringlet",
                  "radius": {
                    "actual": 87.491,
                    "simple": 4.2
                  },
                  "width": {
                    "actual": 0.064,
                    "simple": 0.003
                  },
                  "opticalDepth": 0.08,
                  "color": "0x655f45"
                },
                {
                  "type": "gap",
                  "name": "Bond Gap",
                  "radius": {
                    "actual": 88.7,
                    "simple": 4.2576
                  },
                  "width": {
                    "actual": 0.03,
                    "simple": 0.00144
                  }
                },
                {
                  "type": "ringlet",
                  "name": "1.470Rs Ringlet",
                  "radius": {
                    "actual": 88.716,
                    "simple": 4.2584
                  },
                  "width": {
                    "actual": 0.016,
                    "simple": 0.000768
                  },
                  "opticalDepth": 0.1,
                  "color": "0x655f45"
                },
                {
                  "type": "ringlet",
                  "name": "1.495Rs Ringlet",
                  "radius": {
                    "actual": 90.171,
                    "simple": 4.3282
                  },
                  "width": {
                    "actual": 0.062,
                    "simple": 0.002976
                  },
                  "opticalDepth": 0.12,
                  "color": "0x655f45"
                },
                {
                  "type": "gap",
                  "name": "Dawes Gap",
                  "radius": {
                    "actual": 90.21,
                    "simple": 4.33
                  },
                  "width": {
                    "actual": 0.02,
                    "simple": 0.00096
                  }
                }
              ]
            },
            {
              "type": "ring",
              "name": "B Ring",
              "radius": {
                "actual": 92.0,
                "simple": 4.416
              },
              "width": {
                "actual": 25.5,
                "simple": 1.224
              },
              "opticalDepth": 0.1,
              "color": "0xffe1ab"
            },
            {
              "type": "ring",
              "name": "Cassini Division",
              "radius": {
                "actual": 117.58,
                "simple": 5.644
              },
              "width": {
                "actual": 4.7,
                "simple": 0.2256
              },
              "opticalDepth": 0.1,
              "color": "0x655f45",
              "parts": [
                {
                  "type": "gap",
                  "name": "Huygens Gap",
                  "radius": {
                    "actual": 117.68,
                    "simple": 5.649
                  },
                  "width": {
                    "actual": 0.3,
                    "simple": 0.0144
                  }
                },
                {
                  "name": "Huygens Ringlet",
                  "radius": {
                    "actual": 117.848,
                    "simple": 5.657
                  },
                  "width": {
                    "actual": 0.017,
                    "simple": 0.000816
                  }
                },
                {
                  "type": "gap",
                  "name": "Herschel Gap",
                  "radius": {
                    "actual": 118.234,
                    "simple": 5.675
                  },
                  "width": {
                    "actual": 0.102,
                    "simple": 0.004896
                  }
                },
                {
                  "type": "gap",
                  "name": "Russell Gap",
                  "radius": {
                    "actual": 118.614,
                    "simple": 5.693
                  },
                  "width": {
                    "actual": 0.033,
                    "simple": 0.001584
                  }
                },
                {
                  "type": "gap",
                  "name": "Jeffreys Gap",
                  "radius": {
                    "actual": 118.950,
                    "simple": 5.7096
                  },
                  "width": {
                    "actual": 0.038,
                    "simple": 0.001824
                  }
                },
                {
                  "type": "gap",
                  "name": "Kuiper Gap",
                  "radius": {
                    "actual": 119.405,
                    "simple": 5.7314
                  },
                  "width": {
                    "actual": 0.003,
                    "simple": 0.000144
                  }
                },
                {
                  "type": "gap",
                  "name": "Laplace Gap",
                  "radius": {
                    "actual": 119.967,
                    "simple": 5.7584
                  },
                  "width": {
                    "actual": 0.238,
                    "simple": 0.011424
                  }
                },
                {
                  "type": "gap",
                  "name": "Bessel Gap",
                  "radius": {
                    "actual": 120.241,
                    "simple": 5.7716
                  },
                  "width": {
                    "actual": 0.01,
                    "simple": 0.00048
                  }
                },
                {
                  "type": "gap",
                  "name": "Barnard Gap",
                  "radius": {
                    "actual": 120.312,
                    "simple": 5.775
                  },
                  "width": {
                    "actual": 0.013,
                    "simple": 0.000624
                  }
                }
              ]
            },
            {
              "type": "ring",
              "name": "A Ring",
              "radius": {
                "actual": 122.17,
                "simple": 5.864
              },
              "width": {
                "actual": 14.6,
                "simple": 0.7
              },
              "color": "0xdbb57c",
              "opticalDepth": 0.8,
              "parts": [
                {
                  "type": "gap",
                  "name": "Encke Gap",
                  "radius": {
                    "actual": 133.589,
                    "simple": 6.412
                  },
                  "width": {
                    "actual": 0.325,
                    "simple": 0.0156
                  }
                },
                {
                  "type": "gap",
                  "name": "Keeler Gap",
                  "radius": {
                    "actual": 136.505,
                    "simple": 6.55
                  },
                  "width": {
                    "actual": 0.035,
                    "simple": 0.00168
                  }
                }
              ]
            },
            {
              "type": "ring",
              "name": "Roche Division",
              "radius": {
                "actual": 136.775,
                "simple": 6.565
              },
              "width": {
                "actual": 2.6,
                "simple": 0.1248
              },
              "opticalDepth": 0.04,
              "color": "0x655f45"
            },
            {
              "type": "ring",
              "name": "F Ring",
              "radius": {
                "actual": 140.180,
                "simple": 6.7286
              },
              "width": {
                "actual": 0.25,
                "simple": 0.012
              },
              "opticalDepth": 0.1,
              "color": "0xdbb57c"
            },
            {
              "type": "ring",
              "name": "Janus/Epimetheus Ring",
              "radius": {
                "actual": 149.0,
                "simple": 7.152
              },
              "width": {
                "actual": 5,
                "simple": 0.24
              },
              "opticalDepth": 0.02,
              "color": "0xb89c72"
            },
            {
              "type": "ring",
              "name": "G Ring",
              "radius": {
                "actual": 166.0,
                "simple": 7.968
              },
              "width": {
                "actual": 9,
                "simple": 0.432
              },
              "opticalDepth": 0.015,
              "color": "0xb89c72"
            },
            {
              "type": "ring",
              "name": "Pallene Ring",
              "radius": {
                "actual": 211.0,
                "simple": 10.128
              },
              "width": {
                "actual": 2.5,
                "simple": 0.12
              },
              "opticalDepth": 0.013,
              "color": "0xb89c72"
            }
          ]
        },
        {
          "type": "gas giant",
          "name": "Uranus",
          "radius": {
            "actual": 25.362,
            "simple": 2.5
          },
          "color": "0xd1e7e7",
          "rotationPeriod": -0.71833,
          "axialTilt": 97.77,
          "orbit": {
            "e": 0.04717,
            "aphelion": {
              "actual": 3006390.0,
              "simple": 170
            },
            "perihelion": {
              "actual": 2735560.0,
              "simple": 160
            },
            "period": 30688.5,
            "inclination": 0.773,
            "ascNodeLong": 74.006,
            "perihelionArg": 96.998857
          },
          "satellites": []
        },
        {
          "type": "gas giant",
          "name": "Neptune",
          "radius": {
            "actual": 24.622,
            "simple": 2.5
          },
          "color": "0x5b5ddf",
          "rotationPeriod": 0.6713,
          "axialTilt": 28.32,
          "orbit": {
            "e": 0.008678,
            "aphelion": {
              "actual": 4540000.0,
              "simple": 230
            },
            "perihelion": {
              "actual": 4460000.0,
              "simple": 220
            },
            "period": 60195.0,
            "inclination": 1.77,
            "ascNodeLong": 131.783,
            "perihelionArg": 273.187
          },
          "satellites": []
        },
        {
          "type": "dwarf planet",
          "name": "Pluto",
          "radius": {
            "actual": 1.188,
            "simple": 0.3
          },
          "color": "0xccba99",
          "rotationPeriod": -6.38723,
          "axialTilt": 122.53,
          "orbit": {
            "e": 0.2488,
            "aphelion": {
              "actual": 7375930.0,
              "simple": 350
            },
            "perihelion": {
              "actual": 4436820.0,
              "simple": 240
            },
            "period": 90560.0,
            "inclination": 17.16,
            "ascNodeLong": 110.299,
            "perihelionArg": 113.834
          },
          "satellites": []
        }
      ]
    }
  ]
}
